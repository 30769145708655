import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Input } from "./ui/input";
import { FormEventHandler, useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/firebase/config";
import { Button } from "./ui/button";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e?: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    signInWithEmailAndPassword(auth, username, password)
      .then(() => {
        setUsername("");
        setPassword("");
      })
      .catch((err) => {
        alert("Invalid username or password");
      });
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button variant={"secondary"}>Login</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Login</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          <form onSubmit={handleLogin}>
            <Input placeholder="Username" className="my-2" value={username} onChange={(e) => setUsername(e.target.value)} />
            <Input placeholder="Password" className="my-2" value={password} onChange={(e) => setPassword(e.target.value)} type="password" />
            <input type="submit" className="hidden" />
          </form>
        </DialogDescription>
        <DialogFooter>
          <Button onClick={() => handleLogin()}>Submit</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default Login;
