import { getStorage } from "@firebase/storage";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCRp2Q9C28Qs1UOwxX7csIT3Z6BNZCkW58",
  authDomain: "murrayfield-club.firebaseapp.com",
  projectId: "murrayfield-club",
  storageBucket: "murrayfield-club.appspot.com",
  messagingSenderId: "566917540910",
  appId: "1:566917540910:web:8741fe9447aff05e4b03bf",
  measurementId: "G-FR7JYDGQR0",
};
export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
