import memoryLaneFlower from "./images/memory_lane_flower.png";
import memoryLaneFlowerNobg from "./images/memory_lane_nobg.png";
import curlingImage from "./images/Curling.jpg";
import jenny from "./images/Jenny Rowe new.jpg";
import karen from "./images/Karen Avey new.jpg";
import nicola from "./images/Nicola Bhabutta new.jpg";
import wendy from "./images/Wendy Holyer new.jpg";
import lynne from "./images/Lynne.jpg";
import fundedBy from "./images/funded_byx.png";
import MCT from "./images/MCT_logo.png";
import evoc from "./images/EVOC.png";
import vocal from "./images/vocal.png";
import SDSS from "./images/SDSS-logo.png";
import COEC from "./images/The-City-of-Edinburgh-Council.png";
import Alz from "./images/Alzheimers-Scotland.png";
import { Loader } from "@googlemaps/js-api-loader";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import smoothscroll from "smoothscroll-polyfill";
import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { useAuthState } from "react-firebase-hooks/auth";
import "./style/index.css";
import Login from "./components/login";
import { auth } from "./firebase/config";
import { AnnualReports } from "./components/annual_reports";
import { ContactForm } from "./components/contact_form";
import { Toaster } from "./components/ui/toaster";
import Posts from "./components/posts";
import { Button } from "./components/ui/button";
import { ChevronUp } from "lucide-react";
import { LargeNav, SmallNav } from "./components/nav";
import Messages from "./components/messages";

// import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
// import { getFirestore, collection, getDocs, query, orderBy, limit, addDoc, deleteDoc, doc, Timestamp } from "firebase/firestore";
// import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

// const db = getFirestore(app);
// const storage = getStorage(app);
// const auth = getAuth(app);

smoothscroll.polyfill();

const loader = new Loader({
  apiKey: "AIzaSyBJQJGj2uQWfxJMtp4tFiorQtJJkKUQTmo",
});

interface ModalHandle {
  open: () => void;
  close: () => void;
}
const Modal = forwardRef<ModalHandle, { children: React.ReactNode }>(({ children }, ref) => {
  const [open, setOpen] = useState(false);
  const [mount, setMount] = useState(false);
  const close = () => {
    setOpen(false);
    setTimeout(() => {
      setMount(false);
    }, 300);
  };
  useImperativeHandle(
    ref,
    () => ({
      open: () => {
        setMount(true);
        setTimeout(() => {
          setOpen(true);
        }, 0);
      },
      close,
    }),
    []
  );

  if (!mount) return null;
  return (
    <div className="fixed top-0 left-0 w-full h-full bg-[#000000aa] z-10 duration-300 flex items-center justify-center p-5" style={{ opacity: open ? 1 : 0 }}>
      {children}
    </div>
  );
});

export default function App() {
  useEffect(() => {
    const run = async () => {
      const { Map } = await loader.importLibrary("maps");
      const { Marker } = await loader.importLibrary("marker");
      const MFChurch = { lat: 55.946, lng: -3.240336 };
      const map = new Map(document.getElementById("map"), {
        center: MFChurch,
        zoom: 16,
      });
      new Marker({
        position: MFChurch,
        map,
      });
    };
    run();
  }, []);
  const [user, loading, error] = useAuthState(auth);
  const loggedIn = !!user;

  const homeAnchor = useRef<HTMLAnchorElement>(null);

  const loginRef = useRef<ModalHandle>(null);

  return (
    <div className="">
      <LargeNav />
      <SmallNav />
      <a href="#home" id="home-anchor" className="hidden" ref={homeAnchor}>
        hidden
      </a>
      <div className="w-full h-96 lg:h-screen flex items-center justify-center absolute fade-in" id="home">
        <h2 className="mx-auto bg-black text-center text-white px-6 py-3 tracking-[0.65rem] text-2xl absolute opacity-0 md:opacity-100 duration-300 delay-150">
          THE MURRAYFIELD CLUB
        </h2>
        <h2 className="mx-auto bg-black text-center text-white px-6 py-3 tracking-[0.65rem] text-lg absolute opacity-100 md:opacity-0 duration-300 delay-150 max-w-xs">
          MURRAYFIELD CLUB
        </h2>
      </div>
      <div className="w-full h-96 lg:h-screen sticky top-0 -z-10 flex items-center">
        <img src={memoryLaneFlower} alt="memory-lane-flower" className="mx-auto object-cover" />
      </div>

      <div className="bg-[#fdfdfdee] flex items-center flex-col w-full text-center border-t">
        <div className="px-10">
          <div className="font-medium text-3xl pt-20" id="about">
            ABOUT US
          </div>
          <div className="italic py-7">Who we are</div>
          <div className="max-w-5xl text-left">
            We are the Murrayfield Dementia Project, known locally as The Murrayfield Club. We aim to be a safe, stimulating, supportive, fun place for older members of
            our community. Since establishment in 1990, the club has uniquely and successfully blossomed, providing a service for those who are frail and elderly, or
            living with early stage dementia. We provide benefits that deliver enjoyable activities, companionship and longer healthier opportunities for members and
            carers. The Club is a non-registered Lunch and Activity-based resource for people living in the local area.
          </div>
          <div className="max-w-5xl grid grid-cols-1 md:grid-cols-9 px-4 py-14 gap-16 text-left">
            <img src={curlingImage} className="rounded-2xl shadow-[0_0_12px_-2px_#000] md:col-span-4" id="curling" alt="Curling" />

            <div className="md:col-span-5">
              <div className="mb-4">
                Our accessible day service provides person centred activity sessions in partnership with members and their carers whose needs have been identified by Club
                staff. We provide nutritional meals and snacks during our sessions.
              </div>
              <div className="mb-4">
                Members needs are intertwined with bespoke sessions that relate best to each individual. Knowledge of members built up over time ensures that the
                differing capacities are threaded into seasonally relevant, creative, thematic and therapeutic activity programmes.
              </div>
              <div>
                To enquire about you or a loved one joining please{" "}
                <a href="#contact" className="underline">
                  get in touch
                </a>
                .
              </div>
            </div>
          </div>
          <div className="font-medium text-3xl pt-20" id="staff">
            STAFF
          </div>
          <div className="italic py-7 max-w-5xl text-center">
            We have a professional staff of five. This includes a Project Coordinator, three Support Workers and a Kitchen Assistant. The club welcomes the additional
            support of our dedicated team of volunteers.
          </div>
          {/* <div className="grid auto-cols-fr lg:grid-cols-5 gap-10 sm:gap-10 text-center my-10 lg:max-w-5xl max-w-[400px] mx-auto"> */}
          {/* USE THIS ^^ WHEN ONLY 4 MEMBERS. Also remove the max width on each item ?as well as the change in text size? */}
          <div className="flex flex-wrap items-center justify-center gap-10 sm:gap-10 text-center my-10 max-w-3xl xl:max-w-none mx-auto">
            <div className="flex flex-col items-center hover:scale-[1.02] duration-100">
              <div className="aspect-square rounded-lg overflow-hidden shadow-xl border border-[#555] w-full xxs:max-w-[170px] flex flex-grow max-w-[130px]">
                <img src={jenny} alt="jenny-rowe" className="object-cover object-center w-full h-full" />
              </div>
              <div className="font-medium text-lg sm:text-xl mt-3">Jenny Rowe</div>
              <div className="text-sm sm:text-base font-medium opacity-80">Project Coordinator</div>
            </div>
            <div className="flex flex-col items-center hover:scale-[1.02] duration-100">
              <div className="aspect-square rounded-lg overflow-hidden shadow-xl border border-[#555] w-full xxs:max-w-[170px] flex flex-grow max-w-[130px]">
                <img src={karen} alt="karen-avey" className="object-cover object-center w-full h-full" />
              </div>
              <div className="font-medium text-lg sm:text-xl mt-3">Karen Avey</div>
              <div className="text-sm sm:text-base font-medium opacity-80">Support Worker</div>
            </div>
            <div className="flex flex-col items-center hover:scale-[1.02] duration-100">
              <div className="aspect-square rounded-lg overflow-hidden shadow-xl border border-[#555] w-full xxs:max-w-[170px] flex flex-grow max-w-[130px]">
                <img src={nicola} alt="nicola-bhabutta" className="object-cover object-center w-full h-full" />
              </div>
              <div className="font-medium text-lg sm:text-xl mt-3">Nicola Bhabutta</div>
              <div className="text-sm sm:text-base font-medium opacity-80">Support Worker</div>
            </div>
            <div className="flex flex-col items-center hover:scale-[1.02] duration-100">
              <div className="aspect-square rounded-lg overflow-hidden shadow-xl border border-[#555] w-full xxs:max-w-[170px] flex flex-grow max-w-[130px]">
                <img src={wendy} alt="lynne-wright" className="object-cover object-center w-full h-full" />
              </div>
              <div className="font-medium text-lg sm:text-xl mt-3">Wendy Holyer</div>
              <div className="text-sm sm:text-base font-medium opacity-80">Support Worker</div>
            </div>
            <div className="flex flex-col items-center hover:scale-[1.02] duration-100">
              <div className="aspect-square rounded-lg overflow-hidden shadow-xl border border-[#555] w-full xxs:max-w-[170px] flex flex-grow max-w-[130px]">
                <img src={lynne} alt="lynne-wright" className="object-cover w-full" />
              </div>
              <div className="font-medium text-lg sm:text-xl mt-3">Lynne Wright</div>
              <div className="text-sm sm:text-base font-medium opacity-80">Kitchen Assistant</div>
            </div>
          </div>
          <div className="text-3xl pt-20 text-gray-600 font-medium" id="whatson">
            WHAT'S ON
          </div>
          <div className="italic py-7 max-w-5xl text-center">Here's what's coming up and what's been happening recently at the club!</div>
        </div>
        <div className="w-full">
          <Posts />
        </div>
        <div className="px-10">
          <div className="font-medium text-3xl pt-20 mt-20" id="cafe">
            MEMORY LANE CAFE
          </div>
          <div className="italic py-7 max-w-5xl text-center">Join us for conversation and home baking from 10 'till 12 every Friday - a warm welcome awaits!</div>
          <img src={memoryLaneFlowerNobg} alt="memory-lane-flower" className="mx-auto object-cover my-2" />
          <div className="max-w-5xl text-center my-7">
            The cafe is <em>not</em> hosted at the Murrayfield Parish Church but alternatively is hosted at the Saughtonhall United Reformed Church - 85/87 Saughtonhall
            Drive, EH12 5TR.
          </div>

          <div className="font-medium text-3xl pt-20 mt-20" id="findus">
            FIND US
          </div>
          <div className="italic py-7 max-w-5xl text-center">We are based at The Murrayfield Parish Church in Murrayfield</div>
          <div id="map" className="w-full h-96" />
          <div className="text-center mt-5">
            <div>
              <i className="fa fa-car mr-3" /> On street parking is available but tends to be busy
            </div>
            <div>
              <i className="fa fa-bus mr-3" /> Lothian Buses 12, 26, 31 and 100 will bring you right outside from Edinburgh Central in direction of the Zoo
            </div>
          </div>

          <div className="font-medium text-3xl mt-20 pt-20" id="contact">
            CONTACT US
          </div>
          <div className="italic py-7 max-w-5xl text-center">
            If you have any queries about the club or are in interested in joining, don't hestitate to get in touch!
          </div>
          <ContactForm />

          <div className="max-w-5xl my-20">
            {/* <div className="font-bold mt-7 text-center text-xl">Useful Links</div> */}
            <div className="flex flex-wrap md:grid md:grid-cols-5 gap-10 sm:gap-14 text-center items-center justify-center">
              <a href="https://www.alzscot.org/" target="_blank" rel="noreferrer" className="md:max-w-lg max-w-[100px]">
                <img className="hover:cursor-pointer hover:scale-105 duration-100 " src={Alz} alt="Alzheimers Scotland" />
              </a>
              <a
                href="http://www.evocredbook.org.uk/organisations/murrayfield-dementia-project/001b00000065v5XAAQ"
                target="_blank"
                rel="noreferrer"
                className="md:max-w-lg max-w-[100px]"
              >
                <img className="hover:cursor-pointer hover:scale-105 duration-100 " src={evoc} alt="Edinburgh Voluntary Organisations Council (Red Book)" />
              </a>
              <a href="https://www.vocal.org.uk/" target="_blank" rel="noreferrer" className="md:max-w-lg max-w-[100px]">
                <img className="hover:cursor-pointer hover:scale-105 duration-100 " src={vocal} alt="Voices of Carers Across Lothian (Vocal)" />
              </a>
              <a href="https://www.sdsscotland.org.uk/" target="_blank" rel="noreferrer" className="md:max-w-lg max-w-[100px]">
                <img className="hover:cursor-pointer hover:scale-105 duration-100 " src={SDSS} alt="Self Directed Support in Scotland" />
              </a>
              <a href="https://www.edinburgh.gov.uk/" target="_blank" rel="noreferrer" className="md:max-w-lg max-w-[100px]">
                <img className="hover:cursor-pointer hover:scale-105 duration-100 " src={COEC} alt="City of Edinburgh Council" />
              </a>
            </div>
          </div>
        </div>
        <div className="max-w-5xl px-10">
          <div className="font-bold text-gray-800">
            <em>Charity Number</em>: SCO23210
          </div>
          <div className="my-5">
            The Murrayfield Club operates under Trustees drawn from the ecumenical umbrella organisation representing Murrayfield Parish Church, the Good Shepherd
            Scottish Episcopal Church and Saughtonhall United Reformed Church (MCT).
          </div>
          <div className="mx-auto flex items-center justify-center">
            <AnnualReports />
          </div>
          <div className="grid grid-cols-2 gap-16 mt-14 mb-20">
            <div className="justify-end flex">
              <img src={fundedBy} className="object-contain max-h-44" alt="Funded by Edinburgh Health and Social Care Partnership" />
            </div>
            <a href="https://www.murrayfieldparishchurch.org.uk/partnerships/murrayfield-churches-together-mct" target="_blank" rel="noreferrer">
              <img src={MCT} className="object-contain max-h-44 hover:cursor-pointer hover:scale-105 duration-100" alt="Part of Murrayfield Churches Together" />
            </a>
          </div>
        </div>

        <Modal ref={loginRef}>
          <div className="text-black bg-gray-500 px-10 py-5">
            <div className="relative h-10 w-full min-w-[200px]">
              <input
                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 focus:border-2 focus:border-pink-500 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                placeholder=" "
              />
              <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-pink-500 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-pink-500 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-pink-500 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Input Medium
              </label>
            </div>
            <div className="flex justify-center gap-3">
              <div className="bg-slate-100 px-5 py-2 max-w-fit hover:cursor-pointer hover:opacity-90 duration-200 text-black" onClick={() => loginRef.current?.close()}>
                <i className="fa fa-times mr-3" />
                Cancel
              </div>
              <div className="bg-slate-100 px-5 py-2 max-w-fit hover:cursor-pointer hover:opacity-90 duration-200 text-black" onClick={() => loginRef.current?.close()}>
                <i className="fa fa-sign-in mr-3" />
                Login
              </div>
            </div>
          </div>
        </Modal>

        <footer className="text-center p-16 bg-black w-full flex flex-col items-center text-white">
          <Button onClick={() => homeAnchor.current?.click()} variant={"secondary"}>
            <ChevronUp className="mr-2 h-4 w-4" /> To the top
          </Button>
          <div className={`text-green-600 flex justify-end items-center transition-all duration-500 ${loggedIn ? "opacity-100 h-24" : "opacity-0 h-0"} flex-col mb-2`}>
            <div className="mb-3">Logged in</div>
            {loggedIn && <Messages />}
          </div>
          {loggedIn ? (
            <Button onClick={() => auth.signOut()} variant={"secondary"}>
              Logout
            </Button>
          ) : (
            <Login />
          )}

          <div className="mb-10 text-3xl mt-10">
            <a href="https://www.facebook.com/TheMurrayfieldClub/" target="_blank" rel="noreferrer">
              <i className="fa fa-facebook-official hover:opacity-75 duration-200"></i>
            </a>
          </div>

          <div className="text-xl font-bold max-w-fit hover:text-cyan-200">
            <i className="fa fa-map-marker mr-4" />
            2b Ormidale Terrace, Edinburgh, EH12 6EQ
          </div>
          <div className="text-xl font-bold max-w-fit mt-2 hover:text-cyan-200">
            <i className="fa fa-phone mr-4" />
            07881 288 909
          </div>
          <div className="text-md font-bold max-w-fit hover:text-red-200 hover:cursor-pointer mt-5" onClick={() => window.open("mailto:tomaszstevenssoftware@gmail.com")}>
            Website by Tomasz Stevens
          </div>
        </footer>
      </div>
      <Toaster />
    </div>
  );
}
