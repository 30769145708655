import * as React from "react";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

// const startYear = 2013;
// const endYear = 2022;
// const docs = new Array(endYear - startYear + 1).fill(0).map((_, idx) => endYear - idx);

const docs = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2015, 2014];

export function AnnualReports() {
  return (
    <Select>
      <SelectTrigger className="w-[180px]">
        <SelectValue placeholder="View Annual Reports" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {docs.map((doc) => (
            <a
              key={doc}
              href={`documents/${doc - 1}-${doc}.pdf`}
              className="hover:bg-blue-gray-50 mx-[1px] px-4 py-2 text-sm rounded-sm hover:cursor-pointer block"
              target="_blank"
              rel="noreferrer"
            >
              {doc}
            </a>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
