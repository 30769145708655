import { auth, db, storage } from "@/firebase/config";
import { CollectionReference, Timestamp, addDoc, collection, deleteDoc, doc, getDocs, limit, orderBy, query } from "@firebase/firestore";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useEffect, useRef, useState } from "react";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { z } from "zod";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Form, FormControl, FormField, FormItem, FormMessage } from "./ui/form";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "./ui/use-toast";
import { Loader2 } from "lucide-react";
import { useAuthState } from "react-firebase-hooks/auth";

const PostItem = ({
  title,
  body,
  imageURL,
  timestamp,
  id,
  setDocs,
}: {
  title: string;
  body: string;
  imageURL: string;
  timestamp: { seconds: number };
  id: string;
  setDocs: React.Dispatch<
    React.SetStateAction<
      (Post & {
        id: string;
      })[]
    >
  >;
}) => {
  const [user] = useAuthState(auth);
  const loggedIn = !!user;

  const [isLoading, setIsLoading] = useState(false);

  const removePost = () => {
    setIsLoading(true);

    const docRef = doc(db, `posts/${id}`);

    deleteDoc(docRef)
      .then(() => setDocs((prev) => prev.filter((doc) => doc.id !== id)))
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  return (
    <Dialog>
      <DialogTrigger>
        <div className="item sm:w-[40vw] w-[70vw] md:w-[26vw] mx-[1vw] h-[300px] snap-center hover:scale-105 duration-100 hover:cursor-pointer relative overflow-x-hidden">
          <img src={imageURL} alt={title} className="w-full h-full object-cover" />
          <div className="absolute w-full bottom-0 h-1/2 bg-gradient-to-b from-[#00000000] to-[#000000ff]" />
          {title && body && (
            <div className="absolute w-full bottom-5 px-5">
              <div className="text-card text-xl">{title}</div>
              <div className="text-[#ddd] text-sm font-light leading-5  hidden md:line-clamp-2">{body}</div>
            </div>
          )}
        </div>
      </DialogTrigger>
      <DialogContent className="p-0 m-0 rounded-lg overflow-hidden w-[700px] max-w-[100vw] max-h-[90vh] overflow-y-auto">
        <img src={imageURL} alt={title} className="w-full max-h-[60vh] object-contain border-b" />
        <div className="px-10 pb-5 text-center">
          {title && body && (
            <>
              <div className="text-center text-lg font-medium text-[#0a0a0a] mb-1">{title}</div>
              <div>{body}</div>
            </>
          )}
          {/* <div className="text-sm float-right mt-3">{new Date(timestamp.seconds * 1000).toDateString()}</div> */}
          {loggedIn &&
            (!isLoading ? (
              <Button variant={"destructive"} className="mt-5" onClick={removePost}>
                Delete Post
              </Button>
            ) : (
              <Button disabled className="mt-5" variant={"destructive"}>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Deleting
              </Button>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

const NewPost = ({
  setDocs,
}: {
  setDocs: React.Dispatch<
    React.SetStateAction<
      (Post & {
        id: string;
      })[]
    >
  >;
}) => {
  const FormSchema = z.object({
    title: z.string().optional(),
    body: z.string().optional(),
    image: z.any().refine((file) => file !== undefined, "Image is required."),
    // .refine((files) => files?.[0]?.size <= MAX_FILE_SIZE, `Max file size is 5MB.`)
    // .refine(
    //   (files) => ACCEPTED_IMAGE_TYPES.includes(files?.[0]?.type),
    //   ".jpg, .jpeg, .png and .webp files are accepted."
    // ),
  });

  const closeRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
  });

  const onSubmit = async (data: z.infer<typeof FormSchema>) => {
    setIsLoading(true);
    try {
      const storageRef = ref(storage, `post-images/${new Date().toISOString()}`);
      const uploadTask = await uploadBytes(storageRef, data.image);
      const url = await getDownloadURL(uploadTask.ref);

      const newData = {
        title: data.title ?? "",
        body: data.body ?? "",
        imageURL: url,
        timestamp: Timestamp.now(),
      };

      addDoc(postsRef, newData)
        .then((value) => {
          toast({
            title: "Success!",
            description: <div>Your new post is up and running!</div>,
          });
          setDocs((prev) => [{ ...newData, timestamp: { seconds: Date.now() / 1000 }, id: value.id }, ...prev]);
          //   @ts-ignore
          closeRef.current?.click?.();
        })
        .catch((err) => console.log(err))
        .finally(() => {
          setIsLoading(false);
        });
    } catch (err: any) {
      alert(err?.message ?? "Unknown error occurred");
      setIsLoading(false);
    }
  };

  return (
    <Dialog>
      <DialogTrigger>
        <Button>New Post</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>New Post</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="w-full">
            <div className="grid grid-cols-2 gap-2">
              <FormField
                control={form.control}
                name="title"
                render={({ field }) => (
                  <FormItem className="col-span-2 sm:col-span-1">
                    <FormControl>
                      <Input placeholder="Title" {...field} value={field.value} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="body"
                render={({ field }) => (
                  <FormItem className="col-span-2 sm:col-span-1">
                    <FormControl>
                      <Input placeholder="Body" {...field} value={field.value} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="image"
                render={({ field: { onChange, value, ...rest } }) => (
                  <FormItem className="col-span-2">
                    <FormControl>
                      <Input
                        type="file"
                        {...rest}
                        onChange={(event) => {
                          const file = event?.target.files?.[0];
                          onChange(file);
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            {!isLoading ? (
              <Button type="submit" className="mt-5">
                Create Post
              </Button>
            ) : (
              <Button disabled className="mt-5">
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                Posting
              </Button>
            )}
          </form>
        </Form>
        <DialogClose ref={closeRef} />
      </DialogContent>
    </Dialog>
  );
};

type Post = { body: string; title: string; imageURL: string; timestamp: { seconds: number } };
const postsRef = collection(db, "posts") as CollectionReference<Post>;

const postsQuery = query(postsRef, orderBy("timestamp", "desc"), limit(10));

const Posts = () => {
  const scrollerRef = useRef<HTMLDivElement>(null);
  const [docs, setDocs] = useState<(Post & { id: string })[]>([]);

  const fetchPosts = () => {
    getDocs(postsQuery).then((snapshot) => setDocs(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
  };
  useEffect(() => {
    fetchPosts();
  }, []);

  const [user] = useAuthState(auth);
  const loggedIn = !!user;

  return (
    <div className="scroll-container relative flex justify-center">
      <div className="scroller" id="scroller" ref={scrollerRef}>
        <div className="min-w-[7vw]" id="first-spacer"></div>
        {docs.map((doc) => (
          <PostItem {...doc} key={doc.id} setDocs={setDocs} />
        ))}
        <div className="min-w-[7vw]" id="first-spacer"></div>
      </div>
      <div className="scroll-arrow" id="left-arrow" onClick={() => scrollerRef.current?.scrollBy({ left: -100, behavior: "smooth" })}>
        <i className="fa fa-arrow-left"></i>
      </div>
      <div className="scroll-arrow" id="right-arrow" onClick={() => scrollerRef.current?.scrollBy({ left: 100, behavior: "smooth" })}>
        <i className="fa fa-arrow-right"></i>
      </div>
      {loggedIn && (
        <div className="absolute -bottom-10">
          <NewPost setDocs={setDocs} />
        </div>
      )}
    </div>
  );
};

export default Posts;
