import { Coffee, GanttChartSquare, Mail, Map, MapPinned, Menu, Search, Users } from "lucide-react";
import React, { useEffect, useState } from "react";

export interface NavProps {}

export const LargeNav = () => {
  const [showScrolledNav, setShowScrolledNav] = useState(false);
  window.onscroll = () => {
    setShowScrolledNav(document.body.scrollTop > 250 || document.documentElement.scrollTop > 250);
    // if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
    //   setShowScrolledNav(true);
    // } else if (document.body.scrollTop === 0 && document.documentElement.scrollTop === 0) {
    //   setShowScrolledNav(false);
    // }
  };
  return (
    <>
      <div className="gap-2 hidden lg:flex ratty px-5 pt-1" id="lg-top-nav">
        <a href="#home" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          HOME
        </a>
        <a href="#about" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <Search size="1rem" />
          ABOUT
        </a>
        <a href="#staff" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <Users size={"1rem"} />
          STAFF
        </a>
        <a href="#whatson" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <GanttChartSquare size="1rem" />
          WHAT'S ON
        </a>
        <a href="#cafe" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <Coffee size="1rem" />
          CAFE
        </a>
        <a href="#findus" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <MapPinned size="1rem" />
          FIND US
        </a>
        <a href="#contact" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center transition-all">
          <Mail size="1rem" />
          CONTACT
        </a>
      </div>
      <div className={`fixed top-0 w-screen z-10`}>
        <div
          className={`gap-2 hidden lg:flex ratty px-5 py-1 border-b bg-[#fafafabb] w-full ${showScrolledNav ? "animate-top" : "deanimate-top"}`}
          style={{ backdropFilter: "blur(12px)" }}
        >
          <a href="#home" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            HOME
          </a>
          <a href="#about" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <Search size="1rem" />
            ABOUT
          </a>
          <a href="#staff" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <Users size={"1rem"} />
            STAFF
          </a>
          <a href="#whatson" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <GanttChartSquare size="1rem" />
            WHAT'S ON
          </a>
          <a href="#cafe" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <Coffee size="1rem" />
            CAFE
          </a>
          <a href="#findus" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <MapPinned size="1rem" />
            FIND US
          </a>
          <a href="#contact" className="hover:bg-gray-700 px-3 py-1 rounded-md flex gap-2 items-center hover:text-[#eee] transition-all">
            <Mail size="1rem" />
            CONTACT
          </a>
        </div>
      </div>
    </>
  );
};

export const SmallNav = () => {
  const [navOpen, setNavOpen] = useState(false);
  //   useEffect(() => {
  //     if (navOpen) document.body.style.position = "fixed";
  //   }, [navOpen]);

  return (
    <div className="fixed top-0 lg:hidden z-20">
      {navOpen && (
        <div
          className={`fade-in fill-mode-forwards bg-[#fafafa] w-screen h-screen top-0 absolute justify-center items-center`}
          style={{ backdropFilter: "blur(12px)" }}
          onClick={() => setNavOpen(false)}
        >
          <div className="w-full h-full flex justify-center items-center">
            <div className="bg-[#fdfdfdee] p-5 rounded-lg border flex flex-col w-full max-w-md gap-3 items-center">
              <a href="#home" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                HOME
              </a>
              <a href="#about" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <Search size="1rem" />
                ABOUT
              </a>
              <a href="#staff" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <Users size={"1rem"} />
                STAFF
              </a>
              <a href="#whatson" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <GanttChartSquare size="1rem" />
                WHAT'S ON
              </a>
              <a href="#cafe" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <Coffee size="1rem" />
                CAFE
              </a>
              <a href="#findus" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <MapPinned size="1rem" />
                FIND US
              </a>
              <a href="#contact" className="hover:bg-gray-200 px-3 py-1 rounded-md flex gap-2 items-center w-full justify-center">
                <Mail size="1rem" />
                CONTACT
              </a>
            </div>
          </div>
        </div>
      )}
      <div className="p-4 ml-2 mt-2 inline-block hover:cursor-pointer border rounded-md bg-[#fdfdfdee] z-30 absolute" onClick={() => setNavOpen((prev) => !prev)}>
        <Menu className={`${navOpen ? "rotate-90" : "rotate-0"} transition-all`} />
      </div>
    </div>
  );
};
