import React, { useEffect, useState } from "react";
import { Button } from "./ui/button";
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { CollectionReference, Timestamp, collection, deleteDoc, doc, getDocs, limit, orderBy, query, updateDoc } from "firebase/firestore";
import { db } from "@/firebase/config";
import { Loader2 } from "lucide-react";

const MessagesBlock = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [messages, setMessages] = useState<(Message & { id: string })[]>([]);

  const fetchMessages = async () => {
    try {
      setIsLoading(true);
      await getDocs(messagesQuery).then((snapshot) => setMessages(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))));
    } catch (err: any) {
      alert(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  return isLoading ? (
    <Loader2 className="mr-2 h-4 w-4 animate-spin" />
  ) : (
    <div className="flex-grow justify-start items-start">
      {messages.map((message) => (
        <MessageItem {...message} key={message.id} setMessages={setMessages} />
      ))}
      {messages.length === 0 ? <div className="flex justify-center items-center text-2xl mt-10 h-1/2">No messages</div> : <></>}
    </div>
  );
};

type Message = { message: string; email: string; name: string; timestamp: { seconds: number } };
const messagesRef = collection(db, "messages") as CollectionReference<Message>;

const messagesQuery = query(messagesRef, orderBy("timestamp", "desc"));

const Messages = () => {
  return (
    <Dialog>
      <DialogTrigger>
        <Button variant={"secondary"}>View Messages</Button>
      </DialogTrigger>
      <DialogContent className="m-0 rounded-lg w-[1200px] max-w-[100vw] h-[90vh] overflow-y-auto flex flex-col">
        <DialogHeader>
          <DialogTitle>Messages</DialogTitle>
          <DialogDescription>All your messages that have been submitted via the contact form</DialogDescription>
        </DialogHeader>
        <MessagesBlock />
      </DialogContent>
    </Dialog>
  );
};

const MessageItem = ({
  message,
  name,
  email,
  timestamp,
  id,
  setMessages,
}: Message & {
  id: string;
  setMessages: React.Dispatch<
    React.SetStateAction<
      (Message & {
        id: string;
      })[]
    >
  >;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const removePost = () => {
    setIsLoading(true);

    const docRef = doc(db, `messages/${id}`);

    deleteDoc(docRef)
      .then(() => setMessages((prev) => prev.filter((doc) => doc.id !== id)))
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };

  const isUnknown = timestamp.seconds < 950000000;

  return (
    <div className="px-5 py-3 border rounded-md my-2">
      <div className="font-bold text-[#0a0a0a]">{name}</div>
      <div className="font-medium italic text-[#aaa]">{email}</div>
      <div>{message}</div>
      <div className={`${isUnknown ? "text-orange-600" : "text-[#aaa]"}`}>{!isUnknown ? new Date(timestamp.seconds * 1000).toDateString() : "Unknown date"}</div>
      <div className="flex justify-end">
        {!isLoading ? (
          <Button variant={"destructive"} className="mt-5" onClick={removePost}>
            Delete Message
          </Button>
        ) : (
          <Button disabled className="mt-5" variant={"destructive"}>
            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
            Deleting
          </Button>
        )}
      </div>
    </div>
  );
};
export default Messages;
